/*
|----------------------------------------------
| footer
|----------------------------------------------
*/


.rdp{
    background: #361d1f;
    padding-top:50px; 
    .tit-nivel2{
        border-color:#fbb030;
        color:$cl4;
    }
}

.address{    
    display: table;
    color: $cl4;
    .tit-nivel3{
        color:$cl4;
    }
    p{
        margin-bottom: 20px; 
        color: $cl4;
    }
    &.link-util {
        h3 {
            text-align: center;
        }
       p {
           display: inline;
           padding: 0 5px;
           font-size: 14px;
           text-align: center;
           a {
               &:hover {
                   color: #fbb030;
               }
           }
       }
    }
}


.form-contato{
     margin-left:15px;
}



//cretidos
.creditos{border-top:3px solid $cl4; float:left; margin-top:50px;  padding: 25px 0; width:100%;}
    .creditos{
        small, a{
            font-size:$t-p; color:$cl4;
        }        
    } 
