/*
|----------------------------------------------
| Chrome frame prompt
|----------------------------------------------
*/

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
