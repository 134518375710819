/*
|----------------------------------------------
| Header
|----------------------------------------------
*/

.header{}

//logo
.logo{
    background: url('../images/logo.png') no-repeat;
    @include size(213px,83px);
    margin:35px 0;
    overflow: hidden;
    text-indent: -999px;
    background-size: 100%; float: left}


.conts-top{
    margin-top:45px;    
    font:1em $ft1;
    div, a{
        //height: 35px;
        //background:url('../images/ico-top.png') no-repeat;
        color:$cl1
    }
    .fa{
        color:#fbb030;
        padding-top:7px ;
    }
    
    .fones{
    background-position:0 -55px;     
    }
    .face a{
    background-position:0 -110px;     
    }
}

    //menu
 