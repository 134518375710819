/*
|----------------------------------------------
| Menu
|----------------------------------------------
*/


.b-menu{background:$cl1}

//mark menu
    .home .menu-link[title*="Home"],
    .page-servicos .menu-link[title*="Serv"],
    .single-curso .menu-link[title*="Cursos"],
    .page-equipe .menu-link[title*="Quem"],
    .page-blog .menu-link[title*="Not"],
    .page-cursos .menu-link[title*="Cursos"],
    .page-links .menu-link[title*="Links"],
    .page-quem-somos .menu-link[title*="Quem"]{
      color:#fbb030;
    }



   /*menu resp*/
.anima-menu {
display: block;    
//padding: 1em; 
@include size(30px, 20px);

border-top:2px solid $cl2;
border-bottom:2px solid $cl2;
float:right;   
margin-top:-53px;    
 
position: relative;
    z-index: 999;
clear: right;    
    span{
       font:$t-p $ft1;  margin-left:-50px; text-transform: uppercase; color: $cl2;
    }
}


.anima-menu:before{
    content: "";
    width: 100%;
    height: 2px;
    background:$cl2; 
    position: absolute;
    left:0;
    top:9px;
}



/*cor do link resp*/
.menu-link{ color:#fff; text-decoration:none;}

/*animação do menu resp*/
nav[role=navigation] {
 clear: both;
 -moz-transition: all 0.3s ease-out;
 transition: all 0.3s ease-out;
}

/*class no body para deixar menu escondido no resp*/
.js nav[role=navigation] {
overflow: hidden;
max-height: 0;
}

/*ao acionar a class active o tamanho do menu-link resp seta */
nav[role=navigation].active {
max-height: 26em;
}

/*estilizando o menu resp*/
nav[role=navigation] .menu {
margin: 0;
//clear: right;
width:100%;    
padding: 0;
background:rgba($cl1, .9);
text-transform: uppercase;
border-top: 2px solid #1a1a1a;
}

/*estilizando o menu-link resp*/
nav[role=navigation] .menu-link {
display: block;
padding:15px 10px;
border-bottom: 1px solid rgba($cl4, .2);
font:$t-p $ft1;
    
}
/*estilizando o menu-link:hover resp*/
nav[role=navigation] .menu-link:hover{background:$cl2; color:#fbb030;  transition:color ease-in .2s;}

