/*
|----------------------------------------------
| Reset
|----------------------------------------------
*/
*, html, body {margin:0; padding:0; font-size:100%;}
body {font:0.93em Tahoma, Arial, sans-serif; color:#000; text-align:left; line-height:1.3em;}
	a {text-decoration:none; color:#1a1a1a;}
	//a:hover {}
	img, a img, fieldset {border:0;}
	address, em {font-style:normal;}
	li {list-style-type:none;}
	table {border-collapse:collapse;}
	p{font-family:$ft1, sans-serif; text-align: justify; color: $cl1; line-height:25px;}
	p + p {margin-top:10px;}
