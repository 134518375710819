// **
// query 800px
// **
@media only screen and (max-width:800px) {
    .cem .grid{
        width:90%;
        margin: 0 auto;
    }
        
    
    //header
    
      header.header{
      padding: 0; position: fixed; border-bottom:1px solid $cl2;
      z-index:999; 
      width: 100%;
      background:$cl4;      
          
      }    
    
    .logo{
        width: 150px; height: 43px; margin: 20px 0;
    }

    
    .conts-top{
        display: none;
        width: 100%;
        margin-top: 7px;
        div{
            width:30%;
            float: left;
            background:none;
            padding: 0;
            margin-bottom: 7px;
        }
    }
    
    //cont
    .blc .blc-1{
    margin:0 20px 25px;
        &:last-child{
            margin-left: 20px
        }
    }
    
    
    .nots, .logos{
        .link-center{            
        width: 100%;
        }
    }
    
    .area .input{
        margin-bottom: 7px;
    }

    .prev1, .next1{
    display: none;
    }

    
    .form-contato{
        margin: 0;
    }
    
    //rodapé
    .address{
        float: left!important;
        p{
            color: #fff!important;
            width: 100%;
            
        }
        .number{background: #bbb}
    }
    .creditos{
        small, a{
            font-size: .73em;
        }
        
    }
    
    //inner-content
    .cont .tits{
        margin-bottom: 35px;
    }
    
    .cont .link-center{
    width: 100%;  
    margin-top:-50px;   
    }
    
    
    //cursos
    .page-cursos form div input, .textarea{
    margin-bottom: 7px!important;
    }
    .celular{
        width: 100%;
    }
    .bt{
        margin-top: 7px;
    }
    
    
    //area restrita
    .area-ul {            
        margin: 0;
    }
    

}

// **
// query menu
// **
@media screen and (min-width:800px) {
.anima-menu {
   display: none;
}

.js nav[role=navigation] {
    clear: none;
    max-height: none;
}
/*menu normal*/  
nav[role=navigation] .menu {
    border: 0;
    background:$cl1;
    float:left;
    width:100%;    
}

/*estilizando a lista do menu*/
nav[role=navigation]  .menu li {
    display: inline-block;    
    margin: 0 3.1em;
    text-transform:uppercase;
    
    &:last-child{
        margin: 0; padding: 0; border: 0;
    }
}
/*estilizando o link do menu*/
nav[role=navigation] .menu-link {
    border: 0;    
    font:.94em $ft1;
    color:#fff;   
    padding:15px 20px; 
    }
    .menu li .menu-link[title*="Home"]{
        padding-left: 0;
}
//.menu li:first-child{background: rgba($cl1, .2)}    
    
    
/*estilizando o hover do menu*/
nav[role=navigation] .menu-link:hover{background: rgba($cl1, .2); color:#fbb030}
}




// query tabs

/*
*/