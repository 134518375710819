/*
|----------------------------------------------
| Form
|----------------------------------------------
*/

.form{width: 100%}

.legenda{
    font:$t-m $ft2;
    color: #fff;
}
.label{
    margin-bottom:10px;
    width: 100%;
    float: left;
}
.input, .textarea {
    width:100%;
    height:48px;
    margin-bottom: 7px;
    float:left;
    text-indent: 15px;
    border:1px solid $cl3;
    color:$cl3;}	
        .input:focus, .textarea:focus{
            border-color:$cl1;
            color:$cl1
        }
    .textarea{
        height:100px;
    }

.bt{
  @include size(50px);   
  border: 0;    
  background:$cl1; cursor: pointer;
  display: table;    
  color: $cl4;    
  &:hover{
    background:#fbb030; color:$cl4;
  }
  &:active{
    background:$cl3;
  }
}

.form-contato .bt{
    background: #fbb030; float: right;
    &:hover{
        background:$cl2;
    }
    &:active{
    background:$cl3;
  }
}


div.wpcf7-validation-errors{
  color: red;
  font:$t-p $ft2;
  border: none!important;
  margin: 0;
  padding: 0;
}