/*
|----------------------------------------------
| Placeholder
|----------------------------------------------
*/

%center{
    display : block ;
    margin  : {
        left : auto  ;
        right : auto ;
    }
}

// Clearfix
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}