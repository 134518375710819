/*
|----------------------------------------------
| Inner Content
|----------------------------------------------
*/

.cont{
    padding: 50px 0;
    
    .tits{
        float: left;
        width: 100%;
    }
    .tit-nivel2{
        float: left;
        clear: both;
    }
    p{
        width: 100%;
        display: table;
        clear: left;
    }
    
    .link-center{
        float: right;
        margin-top: -100px;
        
    }
}
.grid{
    
}



//pagina de equipe e de links
.links{
 float: left;
}
    .tit-links{
     font: ft-convert(18) $ft1;
     color:$cl2;
        &:nth-child(1n+2){
            margin-top: 40px;
        }
    }


    .txt-links{
        font-family: $ft2;
        text-align: justify;
        
    }



//page cursos
.page-cursos .rdp{margin-top:150px;}
.tabs-res .tit-nivel3{color: $cl2;}


.single-curso form div, .page-area-restrita form div{
    margin-bottom: 7px;
}
.informacoes{    
    margin: 15px 0;
    li{
       height:30px; font: ft-convert(15) $ft2;
       margin-bottom:7px;
       padding-left: 30px;
       background:url('../images/icon-cursos.png') no-repeat;
    }
    
    li[title*="de"]{
        background-position:0 -57px 
    }    
}



// parceiros
.gal-item{float: left; margin:0 75px 20px 0;}
    .gal-item:nth-child(4n){margin-right: 0}




//area-restrita

.page-area-restrita {
    
    .tit-nivel3{
        clear: both;
        margin-bottom: 15px;
    }
    .tabs li{        
    padding-right: 10px!important;
    }
}



.area-res-container{
     .tit-nivel3{        
        margin-bottom:4px;
    }
}

.page-area-restrita .blc-1{
    margin: 0 0 20px 0;
    .tit-nivel4{
        margin-top: 7px;
    }
}

.sair{    
    background:url('../images/icon-sair.png') no-repeat;
    padding: 5px 0 5px 30px;
    margin-bottom: 15px;
    font-family:$ft2; 
}





.area-ul {            
  
//  border-right: 1px solid $cl3;
  
  margin-right:30px;    
    li{        
      margin: 0px 0 10px 0px;      
        a{
          font: ft-convert(16) $ft2; 
          text-transform: uppercase;
          padding: 7px;
          display: block;
          background:rgba($cl3, .2);        
        }
    }
}


.area-res-container{
    .tit-nivel3{
        margin-bottom: 10px;
    }
}

.page-area-restrita .blc-1{
    padding-bottom: 7px;
    border-bottom: 1px dotted $cl3;
}

.arquivos{
    
}
.arquivos-li{
    display: block;
    margin-bottom: 15px;
    
    a{
    font: ft-convert(14) $ft2; 
    }
    
}

.jpg, .pdf{    
    padding:7px 0 7px 35px;
}
.jpg{
    background:url('../images/jpg.png') 0 0 no-repeat;
    
}
.pdf{
    background:url('../images/pdf.png') no-repeat;
    
}




// Ajustes E-deas 17/19/2018
.single {
  &-servicos {
    .cont {
      .tits {
        .tit {
          &-nivel2 {
            float: none !important;
          }
        }
      }
      .imagem-destacada  {
        text-align: center !important;
        padding: 40px 0;
      }
      .conteudo {
        ul {
          margin:0;
          text-align: center;
          li {
            margin: 0 0 15px;
            &:before {
              content: '';
              background: url('../images/yes.png');
              background-size: 100%;
              @include size(20px,20px);
              position: absolute;
              margin-left: -30px;
            }
          }
        }
      }
    }
  }
}





