/*
|----------------------------------------------
| Variables
|----------------------------------------------
*/

// Cores
$cl1 : #5c3035;  // black
$cl2 : #c0392b;  // red
$cl3 : #96989a;  // gray
$cl4 : #fff;  // white
/*$cl5 : #f1c40f;    // yellow   */ 

// Fontes
//$ft1  : "hammersmithoneregular";
//$fta1 : 'hammersmithone-regular-webfont';

$ft1  : "robotolight";
$fta1 : 'roboto-light-webfont';

$ft2  : "montserratregular";
$fta2 : 'montserrat-regular-webfont';
$fta2 : 'montserrat-regular-webfont';


// Tamanhos
$t-pp    : .750em;      // 12px
$t-p      : .875em;     // 14px
$t-m     : 1em;         // 16px
$t-g      : 1.5em;      // 24px
$t-gg    : 1.875em;   // 30px
$t-ggg  : 1.875em;   // 30px

// Grid 
$pad  : 10px;
$full : 100%;
$center : 990px;





// Variaveis conteudo 