/*
|----------------------------------------------
| Grid
|----------------------------------------------
*/

.full{
    width: $full;
}

.grid {
  width: $center;    
  @extend %center;
//background: #990;
}

//pure img
.pure-img{
    max-width: 100%;
    height: auto;
    display: block;
}

/*
[class*='col-'] {
	float: left;
    padding-right: $pad;
    
    .grid &:last-of-type {
        padding-right: 0;
    }
}
.col-2-3 {width: 66.66%;}
.col-1-3 {width: 33.33%;}

.col-1-2 {width: 50%;}
.col-1-4 {width: 25%;}
.col-1-8 {width: 12.5%;}
*/

.module {
  padding: $pad;
  background: #eee;
}

/* Opt-in outside padding */
.grid-pad {
  padding: $pad 0 $pad $pad;
  [class*='col-']:last-of-type {
    padding-right: $pad;
  }
}